import axios from '@/utils/vendors/axios'
import { AxiosPromise } from 'axios'
import { BaseResponseMeta } from '@/utils/api/base-response'

import {
  ShiftListRequest,
  ListAbsencesParams,
} from '../models/shifts-calendar.model'

export default {
  listShifts(
    params: ListAbsencesParams,
    dateRange: Date[]
  ): AxiosPromise<{
    data: any
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new ShiftListRequest(params, dateRange))
  },
}
