

















import {
  defineComponent,
  computed,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import store from '@/store'
import BaseShiftplan from '@/app/util-modules/ui/shiftplan/BaseShiftplan.vue'

import {
  SHIFTS_CALENDAR_ACTION_LIST_SHIFTS,
  SHIFTS_CALENDAR_ACTION_CLEAR_SHIFTS,
} from '@/app/modules/shifts-calendar/store/actions/shifts-calendar.actions.names'
import { MODULE_NAME as SHIFT_PLAN_MODULE_NAME } from '@/app/modules/shifts-calendar/shifts-calendar.module'
import { AREA_TYPE } from '@/app/core/area/models/area.model'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import { processShiftData } from './shiftplan.utils'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import {
  SHIFTS_CALENDAR_EVENT,
  Attributes as ShiftAttributes,
} from '../models/shifts-calendar.model'
import { isPast } from 'date-fns'

export default defineComponent({
  name: 'Shiftplan',
  components: {
    BaseShiftplan,
    BaseLoading,
  },

  setup() {
    const shiftData: any = ref([])

    const $actions = useActionTracker({
      listShifts: SHIFTS_CALENDAR_ACTION_LIST_SHIFTS,
    })

    const weekDateRange = ref<Date[]>([new Date()])

    const handleWeekRangeChange = (weekRange: Date[]) => {
      weekDateRange.value = weekRange
      store.dispatch(SHIFTS_CALENDAR_ACTION_LIST_SHIFTS, weekRange)
    }

    const areas = computed(() => {
      const areaList = store.state[SHIFT_PLAN_MODULE_NAME].records[AREA_TYPE]
      let sortedAreaList = [...areaList].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      return sortedAreaList
    })

    const shifts = computed(() => {
      return store.state[SHIFT_PLAN_MODULE_NAME].records[
        SHIFTS_CALENDAR_EVENT
      ].map((shift: ShiftAttributes) => ({
        id: shift.id,
        start: new Date(shift.starts_at),
        numberOfEmployees: shift.number_of_employees,
        users: shift.users,
        resource: shift.working_area.id,
        color: shift.working_area.color,
        periods: {
          start: new Date(shift.starts_at),
          end: new Date(shift.ends_at),
        },
        shift,
        isPast: isPast(new Date(shift.ends_at)),
      }))
    })

    watch(
      () => shifts.value,
      () => {
        shiftData.value = processShiftData(
          areas.value,
          shifts.value,
          weekDateRange.value
        )
      }
    )

    onUnmounted(() => {
      store.dispatch(SHIFTS_CALENDAR_ACTION_CLEAR_SHIFTS)
    })

    return {
      areas,
      shifts,
      $actions,
      shiftData,

      handleWeekRangeChange,
    }
  },
})
