export function processShiftData(
  areas: any[],
  shifts: any[],
  weekDateRange: Date[]
) {
  const emptySlot: any[] = []

  areas.forEach((area) => {
    const shiftsByDay = Array.from({ length: 7 }, () => 0)

    shifts.forEach((shift) => {
      if (shift.resource === area.id) {
        const start = new Date(shift.start)
        const dayOfWeek = (start.getDay() + 6) % 7 // Adjusting to start from Monday
        shiftsByDay[dayOfWeek]++
      }
    })

    const maxShifts = Math.max(...shiftsByDay)

    shiftsByDay.forEach((dayShift, index) => {
      let dayShiftNum = dayShift
      while (dayShiftNum < maxShifts) {
        const dateForEmptySlot = new Date(weekDateRange[0])
        const dayOfWeek = (dateForEmptySlot.getDay() + 6) % 7 // Adjusting to start from Monday
        const daysToAdd = index - dayOfWeek
        dateForEmptySlot.setDate(dateForEmptySlot.getDate() + daysToAdd)

        const shiftStartTime = shifts.find(
          (shift) =>
            shift.resource === area.id &&
            new Date(shift.start).getDay() === dateForEmptySlot.getDay()
        )?.start

        if (shiftStartTime) {
          dateForEmptySlot.setHours(shiftStartTime.getHours() + 1)
          dateForEmptySlot.setMinutes(shiftStartTime.getMinutes())
          dateForEmptySlot.setSeconds(shiftStartTime.getSeconds())
        }

        emptySlot.push({
          id: null,
          resource: area.id,
          start: new Date(dateForEmptySlot),
          placeholder: true,
          color: area.color,
        })
        dayShiftNum++
      }
    })
  })

  return [...shifts, ...emptySlot]
}
