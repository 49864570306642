import state, { makeEmptyState } from './shifts-calendar.state'
import actions from './actions/shifts-calendar.actions'
import mutations from './mutations/shifts-calendar.mutations'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

export { makeEmptyState }
