import { Module } from 'vuex'
import { RouteConfig } from 'vue-router'
import store, { RootState } from '@/store'
import router from '@/router'
import { resetState } from '@/utils/store'
import { ShiftsCalendarState } from './store/shifts-calendar.state'

let isInstalled = false

// module name must match feature hint from backend API response
export const MODULE_NAME = 'ShiftsCalendar'

export const STATE_RESET_MUTATION_NAME = `resetState`

export function install(): Promise<void> {
  const storeInstall = import(
    /* webpackChunkName: 'ShiftsCalendarModule' */ './store/shifts-calendar.store.js'
  ).then(exports.registerStore)

  const routeInstall = import(
    /* webpackChunkName: 'ShiftsCalendarModule' */ './routes/shifts-calendar.routes'
  ).then(exports.addRoutes)

  return Promise.all([storeInstall, routeInstall]).then(() => {
    isInstalled = true
  })
}

export function uninstall() {
  if (!isInstalled) {
    return
  }
  store.commit(`${MODULE_NAME}/${STATE_RESET_MUTATION_NAME}`)
  store.unregisterModule(MODULE_NAME)

  isInstalled = false
}

export function registerStore(localStore: {
  default: Module<ShiftsCalendarState, RootState>
  makeEmptyState(): ShiftsCalendarState
}) {
  // dynamically register state reset mutation for use in uninstall()
  if (!localStore.default.mutations) {
    localStore.default.mutations = {}
  }
  localStore.default.mutations[STATE_RESET_MUTATION_NAME] = function (
    state: ShiftsCalendarState
  ) {
    resetState(state, localStore.makeEmptyState())
  }
  store.registerModule(MODULE_NAME, localStore.default)
}

export function addRoutes(localRoutes: { default: Array<RouteConfig> }) {
  for (const route of localRoutes.default) {
    router.addRoute(route)
  }
}
