





















import { defineComponent } from '@vue/composition-api'
import CalendarMobileContent from '@/app/modules/calendar/components/CalendarMobileContent.vue'
import Shiftplan from '@/app/modules/shifts-calendar/components/Shiftplan.vue'
import BaseTopBarNotification from '@/app/util-modules/ui/top-bar-notification/BaseTopBarNotification.vue'

export default defineComponent({
  name: 'ShiftplanPage',
  components: {
    CalendarMobileContent,
    Shiftplan,
    BaseTopBarNotification,
  },
})
