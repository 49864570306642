import { State, RecordsCollection, RecordsIndexes } from '@/utils/jsonapi/types'
import { AREA_TYPE } from '@/app/core/area/models/area.model'
import { SHIFTS_CALENDAR_EVENT } from '../models/shifts-calendar.model'

export interface ShiftsCalendarState extends State {
  records: RecordsCollection
  recordsIndexes: RecordsIndexes
}

export const makeEmptyState = (): ShiftsCalendarState => ({
  records: {
    [AREA_TYPE]: [],
    [SHIFTS_CALENDAR_EVENT]: [],
    user: [],
  },
  recordsIndexes: {},
})

export default makeEmptyState()
