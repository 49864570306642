import { shortname } from '@/utils/store'
import {
  setRecords,
  addIncluded,
  appendRecords,
} from '@/utils/jsonapi/records.mutations'
import * as mutationNamesObj from './shifts-calendar.mutations.names'
import { AREA_TYPE } from '@/app/core/area/models/area.model'
import { ShiftsCalendarState } from '../shifts-calendar.state'
import { SHIFTS_CALENDAR_EVENT } from '../../models/shifts-calendar.model'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.SHIFTS_CALENDAR_MUTATION_SET_RECORDS]: setRecords,

  [mutationNames.SHIFTS_CALENDAR_MUTATION_APPEND_RECORDS]: appendRecords,

  [mutationNames.SHIFTS_CALENDAR_MUTATION_ADD_INCLUDED]: addIncluded,

  [mutationNames.SHIFTS_CALENDAR_MUTATION_CLEAR_INCLUDED]: (
    state: ShiftsCalendarState
  ) => {
    state.records[AREA_TYPE] = []
    state.records[SHIFTS_CALENDAR_EVENT] = []
  },
}
