import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'

import { ShiftsCalendarState } from '../shifts-calendar.state'
import * as actionNamesObj from './shifts-calendar.actions.names'
import * as mutationNamesObj from '../mutations/shifts-calendar.mutations.names'
import {
  SHIFTS_CALENDAR_EVENT,
  ListAbsencesParams,
} from '../../models/shifts-calendar.model'
import { BaseResponseMeta } from '@/utils/api/base-response'
import { AxiosResponse } from 'axios'

import api from '../../network/shifts-calendar.api'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export type ListResponse = AxiosResponse<{
  data: []
  meta: BaseResponseMeta
  included: []
}>

const fetchShifts = async (
  params: ListAbsencesParams,
  dateRange: Date[]
): Promise<{ included: []; data: []; meta: BaseResponseMeta }> => {
  const { data: response }: ListResponse = await api.listShifts(
    params,
    dateRange
  )

  return {
    included: response.included,
    data: response.data,
    meta: response.meta,
  }
}

const filteredIncluded = (included: []) => {
  return included.length
    ? included.filter(
        (entity: any) =>
          !(entity.type === 'user' && entity.attributes.status === 'inactive')
      )
    : []
}

export default {
  [actionNames.SHIFTS_CALENDAR_ACTION_LIST_SHIFTS]: async (
    { rootState, commit, dispatch }: ActionContext<ShiftsCalendarState, any>,
    dateRange: Date[]
  ) => {
    const params = {
      workspaceId: rootState.workspace.workspace.id,
      pagination: { items: 30, page: 1 },
      sorting: {},
      filter: {},
    }
    const { included, data, meta } = await fetchShifts(params, dateRange)
    const filteredIncludedData = filteredIncluded(included)
    if (data.length) {
      dispatch(actionNames.SHIFTS_CALENDAR_ACTION_CLEAR_SHIFTS)
      commit(mutationNames.SHIFTS_CALENDAR_MUTATION_ADD_INCLUDED, {
        records: filteredIncludedData,
      })

      commit(mutationNames.SHIFTS_CALENDAR_MUTATION_SET_RECORDS, {
        type: SHIFTS_CALENDAR_EVENT,
        records: data,
      })
      const { current_page, total_pages } = meta

      if (total_pages <= 1) return

      for (let page = current_page + 1; page <= total_pages; page++) {
        const pageParams = {
          ...params,
          pagination: { ...params.pagination, page },
        }
        const { included: pageIncluded, data: pageData } = await fetchShifts(
          pageParams,
          dateRange
        )

        const filteredPageIncludedData = filteredIncluded(pageIncluded)

        commit(mutationNames.SHIFTS_CALENDAR_MUTATION_ADD_INCLUDED, {
          records: filteredPageIncludedData,
        })

        commit(mutationNames.SHIFTS_CALENDAR_MUTATION_APPEND_RECORDS, {
          type: SHIFTS_CALENDAR_EVENT,
          records: pageData,
        })
      }
    }
  },

  [actionNames.SHIFTS_CALENDAR_ACTION_CLEAR_SHIFTS]: ({
    commit,
  }: ActionContext<ShiftsCalendarState, any>) => {
    commit(mutationNames.SHIFTS_CALENDAR_MUTATION_CLEAR_INCLUDED)
  },
}
