import { RecordRelationship } from '@/utils/jsonapi/types'
import BaseRequest, {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { generateDateRangeQueryParam } from '@/utils/helpers'

import { SHIFTS_CALENDAR_ENDPOINT_LIST_SHIFTS } from '../network/shifts-calendar.endpoints'

export const SHIFTS_CALENDAR_EVENT = 'event'

export interface User {
  id?: RecordId
}

export interface Attributes {
  id: RecordId
  starts_at: string
  ends_at: string
  number_of_employees: string
  users: User[]
  working_area: {
    id: RecordId
    color: string
  }
}

export interface ShiftRaw {
  id: RecordId
  attributes: Attributes
  relationships: RecordRelationship[]
}
export interface ListAbsencesParams {
  workspaceId: RecordId
  pagination: BaseRequestPagination
  sorting: BaseRequestSorting
  filter: Record<string, string>
}

export class ShiftListRequest extends BaseRequest {
  constructor(params: ListAbsencesParams, dateRange: Date[]) {
    super()

    const baseURL = SHIFTS_CALENDAR_ENDPOINT_LIST_SHIFTS(params.workspaceId)

    const dateFilter = generateDateRangeQueryParam(dateRange)

    super.url = `${baseURL}${dateFilter}`

    super.include = 'users,working_area'

    super.pagination = params.pagination
  }
}
